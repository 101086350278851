import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class ClaimService {
  // getClientSites(client, camerasOnly = false){
  //     return axios.get(API_URL + 'client/' + client + '/sites?hascameras=' + camerasOnly, {headers: authHeader()});
  // }
  getClaims(
    client,
    filters = { page: 1, search: {}, types: {}, groups: {}, preferences: {} }
  ) {
    let url = API_URL + "claims?client_id=" + client + "&page=" + filters.page;
    if (this._qc(filters.search.registration)) {
      url += "&registration=" + filters.search.registration;
    }
    if (this._qc(filters.search.status)) {
      url += "&status=" + filters.search.status;
    }
    if (this._qc(filters.search.referenceNumber)) {
      url += "&reference=" + filters.search.referenceNumber;
    }
    if (filters.types.all === true) {
      url += "&driveoff=true&nmop=true&parking=true";
    } else {
      if (filters.types.driveoff === true) {
        url += "&driveoff=true";
      }
      if (filters.types.nmop === true) {
        url += "&nmop=true";
      }
      if (filters.types.parking === true) {
        url += "&parking=true";
      }
    }
    if (filters.groups.all === true) {
      url += "&actionable=true&waiting=true&nonactionable=true";
    } else {
      if (filters.groups.actionable === true) {
        url += "&actionable=true";
      }
      if (filters.groups.waiting === true) {
        url += "&waiting=true";
      }
      if (filters.groups.nonactionable === true) {
        url += "&nonactionable=true";
      }
    }

    if (filters.preferences.hideLocked === true) {
      url += "&locked=true";
    }
    if (filters.preferences.hasNotes === true) {
      url += "&hasnotes=true";
    }
    if (filters.preferences.hasPayments === true) {
      url += "&haspayments=true";
    }

    return axios.get(url, { headers: authHeader() });
  }

  getClaim(client, claim) {
    return axios.get(
      "https://api.varsanpr.com/api/claims/" + claim + "?client_id=" + client,
      { headers: authHeader() }
    );
  }

  _qc(val) {
    if (val === null) return false;
    if (val === undefined) return false;

    if (typeof val === "string") {
      if (val.length < 1) return false;
    }

    return true;
  }

  createNote(client, claim, note) {
    return axios.put(
      "https://api.varsanpr.com/api/claims/" + claim + "/notes",
      {
        client_id: client,
        note: note,
      },
      { headers: authHeader() }
    );
  }

  deleteNote(client, claim, note) {
    return axios.delete(API_URL + "claims/" + claim + "/notes/" + note, {
      data: { client_id: client },
      headers: authHeader(),
    });
  }

  getClaimVehicle(client, claim, vehicle) {
    return axios.get(
      API_URL +
        "vehicle/" +
        vehicle +
        "/claims?claim_id=" +
        claim +
        "&client_id=" +
        client,
      { headers: authHeader() }
    );
  }

  // getClaimPayments(client, claim){
  //   return axios.get(API_URL + 'claims/' + claim + '/payments?client_id=' + client, {headers: authHeader()});
  // }

  // Create a new Drive Off claim
  createDriveoff(data) {
    return axios.put("https://api.varsanpr.com/api/claims/driveoff", data, {
      headers: authHeader(),
    });
  }

  // Create a new No Means of Payment claim
  createNmop(data) {
    return axios.put("https://api.varsanpr.com/api/claims/nmop", data, {
      headers: authHeader(),
    });
  }

  // Create a new Cnaf claim
  createCnaf(data) {
    return axios.put("https://api.varsanpr.com/api/claims/cnaf", data, {
      headers: authHeader(),
    });
  }

  uploadEvidenceToClaim(client, claim, file, type, comments) {
    let data = new FormData();
    data.append("file", file, file.name);
    data.append("type", type);
    data.append("client_id", client);
    return axios.put(
      "https://api.varsanpr.com/api/claims/evidence/" + claim,
      data,
      { headers: authHeader() }
    );
  }

  downloadEvidence(client, claim, asset) {
    return axios.get(
      `https://api.varsanpr.com/api/claims/${claim}/evidence/${asset}?client_id=${client}`,
      {
        responseType: "arraybuffer",
        headers: authHeader(),
      }
    );
  }

  updateEvidenceType(client_id, claim_id, asset_id, type) {
    return axios.patch(
      `${API_URL}claims/${claim_id}/evidence/${asset_id}`,
      { client_id: client_id, type: type },
      { headers: authHeader() }
    );
  }

  editFuel(claim, data) {
    return axios.patch(`${API_URL}claims/${claim}/fuel`, data, {
      headers: authHeader(),
    });
  }

  getClaimPayments(claim, client) {
    return axios.get(
      `https://api.varsanpr.com/api/claims/${claim}/payments?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  createPayment(claim, data) {
    return axios.put(`${API_URL}claims/${claim}/payments`, data, {
      headers: authHeader(),
    });
  }

  getStatuses(client) {
    return axios.get(
      `https://api.varsanpr.com/api/claims/statuses?client_id=${client}`,
      { headers: authHeader() }
    );
  }

  uploadToExistingClaim(claim, data) {
    return axios.put(`${API_URL}claims/${claim}/evidence`, data, {
      headers: authHeader(),
    });
  }
}

export default new ClaimService();
